var stickyHeader = true;
// var transformHomepageDonationAmountImages = false;
// var flyoutNav = false;
// var fullscreenNav = false;
// var homeQuickGivingPreFooter = false;
var countUpStats = true;
var countUpStatsDuration = 3000;
// var subsiteHijackMainNav = ['example-subsite-name'];
// var subsiteHijackMainLogoClickDestination = ['example-subsite-name'];
// var carouselBackgroundVideoYouTubeID = ''; // see https://fresco-docs.netlify.com/#/carousel/background-video
// var feedTitleLinks = ['/first-feed-title-link', '/second-feed-title-link']; // see https://fresco-docs.netlify.com/#/home-feeds/feed-title-links
// var feedItemsCarouselBreakpoint = 576; // see https://fresco-docs.netlify.com/#/home-feeds/feed-items-carousel
// var feedItemsCarouselAutoplay = true;
// var feedItemsCarouselAutoplaySpeed = 4000;
var lualBodyClasses = ["winter-appeal-2021"];

$(".homeBox1, .homeBox2").prependTo(".homeFeaturesWrapper").wrapAll("<div class='homeFeatures homeFeaturesTop' />");

$(".footerBox[class*='ocial']").insertBefore(".pageFooterWrapper");

$(".eventWhenWrapper").clone().insertBefore(".postFooterWrapper");

// Form button text change
$("a.button.customFormSubmit").text("Submit");

// 988542 SA - Donate button bug on the fundraising appeal featured on a subsite
if ($('body').hasClass('subsite')) {
  $(".appealActionsWrapper").insertBefore(".contentBlock");
}